import classNames from 'classnames';
import { FC } from 'react';
import { IllustrationType } from '../../enums/IllustrationType';

interface IllustrationProps {
  img?: IllustrationType; //PPI - Legacy, bylo by dobré ji postupně odbourat
  src?: ILLUSTRATIONSPRITE;
  className?: string;
  alt?: string;
}

const Illustration: FC<IllustrationProps> = ({ img, className, src, alt }) => {
  const finalSrc = src || img;
  if (src === undefined && img === undefined) {
    throw new Error(
      'There is a missing source img for the illustration component'
    );
  }
  return (
    <img
      className={classNames(className && className)}
      src={`/imgs/illustrationsNew/${finalSrc}.svg`}
      alt={alt || img?.toString()}
    />
  );
};
export default Illustration;
